import { Image, ImageProps } from 'antd';

import ImgFailed from '@assets/icons/img_load_failed.png';

/**
 * 统一默认值的Image组件
 */
export function CustomImage(props: ImageProps) {
    return (
        <Image fallback={ImgFailed} {...props} />
    );
}