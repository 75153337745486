import { useEffect, useState } from 'react';

// map
import AMapLoader from '@amap/amap-jsapi-loader';
import "@amap/amap-jsapi-types";

const MapComponent: React.FC<{ setMap: React.Dispatch<React.SetStateAction<AMap.Map | undefined>> }> = ({ setMap }) => {
    const [id, setId] = useState(`container${Math.random()}`);

    console.log(id);

    useEffect(() => {
        AMapLoader.load({
            key: "264e6012594f67ffb53b1964e294eb94",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((map: typeof AMap) => {
            setMap(() => {
                try {
                    console.log(id);
                    let newMap: AMap.Map = new map.Map(id, { //设置地图容器id
                        // viewMode: "3D",         //是否为3D地图模式
                        zoom: 17,                //初始化地图级别
                    })
                    return newMap;
                } catch (e) {
                    console.error(e);
                }
            });
        }).catch(e => {
            console.log(e);
        })
    }, []);

    return (
        <div
            id={id}
            className="map"
            style={{ height: '35vh', width: '100%' }}
        ></div>
    );
}

function LocationDisplay({ location }: { location: AMap.LngLat | AMap.Vector2 }) {
    const [map, setMap] = useState<AMap.Map>();

    useEffect(() => {
        if (map) {
            const marker = new AMap.Marker({
                position: location,   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                title: '定位',
            });

            map.clearMap();
            map.add(marker);
            map.setCenter(location);
        }
    }, [map, location]);

    return <MapComponent setMap={setMap}></MapComponent>;
}

export default LocationDisplay;
