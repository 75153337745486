import React, { useEffect, useState } from 'react';

// apis
import { AppraiseForm, getOrderDetail, IOrderDetailOrder, IOrderDetailStep, toAppraise } from '../../apis/order';

// antd
import { Button, Divider, Form, message, Radio, Rate, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { useWatch } from 'antd/es/form/Form';

class AppraiseType {
    /**
     * 统一评价
     */
    static UNIFICATION = 1;
    /**
     * 分步骤评价
     */
    static SEPARATION = 2;
}

function AppraiseFormPage() {
    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm<AppraiseForm>();

    const [detail, setDetail] = useState<IOrderDetailOrder>();
    const [stepList, setStepList] = useState<IOrderDetailStep[]>([]);

    useEffect(() => {
        getOrderDetail({ order_id: Number(id) }).then(res => {
            setDetail(res.data.order);
            setStepList(res.data.list.filter(item => item.can_company_appraise));
        });
    }, [form]);

    // 提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const submit = async () => {
        await form.validateFields();

        let rawForm = JSON.parse(JSON.stringify(form.getFieldsValue(true)));

        try {
            setSubmitLoading(true);
            let res = await toAppraise({
                ...rawForm,
            });
            message.success(res.msg);
            navigate('/order');
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    }

    const type = useWatch('appraise_type', form);
    useEffect(() => {
        if (type === AppraiseType.SEPARATION) {
            form.setFieldsValue({
                score: undefined,
                appraise: undefined,
                order_process_step: stepList.map(item => ({ id: item.id })),
            })
        } else {
            form.setFieldsValue({
                order_process_step: undefined,
            })
        }
    }, [type])

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>业务评价 - {detail?.title ?? '加载中'}</div>
            </div>

            {
                detail
                    ? <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        initialValues={{
                            order_id: Number(id),
                            appraise_type: AppraiseType.SEPARATION
                        }}
                    >
                        <Form.Item
                            label="评价方式"
                            name='appraise_type'
                            rules={[{ required: true, message: '请选择评价方式' }]}
                        >
                            <Radio.Group>
                                <Radio value={1}>统一评价</Radio>
                                <Radio value={2}>分步骤评价</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {
                            type === AppraiseType.SEPARATION
                                ? <>
                                    {
                                        stepList.map((item, index) => {
                                            return <>
                                                <Divider></Divider>

                                                <Form.Item
                                                    label={`${item.process_step_title}步骤：`}
                                                    name={['order_process_step', index, 'score']}
                                                    rules={[{ required: item.can_company_appraise === true, message: '请选择评分' }]}
                                                >
                                                    <Rate disabled={item.can_company_appraise === false} allowHalf />
                                                </Form.Item>

                                                <Form.Item
                                                    label="评价"
                                                    name={['order_process_step', index, 'appraise']}
                                                >
                                                    <TextArea disabled={item.can_company_appraise === false} rows={4} placeholder="请输入评价" />
                                                </Form.Item>
                                            </>
                                        })
                                    }
                                </>
                                : <>
                                    <Form.Item
                                        label="评分"
                                        name='score'
                                        rules={[{ required: true, message: '请选择评分' }]}
                                    >
                                        <Rate allowHalf />
                                    </Form.Item>

                                    <Form.Item
                                        label="评价"
                                        name='appraise'
                                        rules={[{ required: true, message: '请输入评价' }]}
                                    >
                                        <TextArea rows={4} placeholder="请输入评价" />
                                    </Form.Item>
                                </>
                        }

                        <Form.Item>
                            <Space>
                                <Button type='primary' onClick={submit} loading={submitLoading}>提交表单</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                    : null
            }

        </div >
    );
}


export default AppraiseFormPage;