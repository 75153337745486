import React, { useState } from 'react';
import BreadcrumbBar from '../components/breadcrumb/breadcrumb';
import NavigatorBar from '../components/navigator/navigator';
import ShortcutAccess from '../components/shortcutAccess/shortcutAccess';

import './defaultLayout.scss'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';

import { Button, Grid } from 'antd';
import { Outlet } from 'react-router-dom';

const { useBreakpoint } = Grid;

function DefaultLayout({ children }: { children?: React.ReactNode }) {
    const screens = useBreakpoint();

    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <>
            <div className='default-layout'>
                {/* 左侧界面 */}
                <div className={`layout__left ${!screens.md && !collapsed && 'layout__left-close'} ${!screens.md && 'mobile'}`} >
                    {/* 导航栏 */}
                    <NavigatorBar></NavigatorBar>
                    {/* 遮罩 */}
                    {collapsed && <div className='mask' onClick={toggleCollapsed}></div>}
                </div>
                {/* 右侧界面 */}
                <div className='layout__right'>
                    <div className='layout__right__top'>
                        {/* 悬浮 */}
                        <div className='layout__right__breadcrumb'>
                            <div className='content'>
                                <div className='flex-box'>
                                    {/* 响应式导航栏按钮 */}
                                    {
                                        !screens.md && (
                                            <Button className='nav-btn' type="primary" onClick={toggleCollapsed} >
                                                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                            </Button>
                                        )
                                    }

                                    {/* 面包屑 */}
                                    <BreadcrumbBar></BreadcrumbBar>
                                </div>
                                <ShortcutAccess></ShortcutAccess>
                            </div>
                        </div>
                    </div>
                    {/* 内容界面 */}
                    <div className='layout__right__children'>{children ?? <Outlet />}</div>
                </div>
            </div>
        </>
    );
}

export default DefaultLayout;