import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";

export interface IRequirementListProcess {
    id: number,
    title: string,
    desc: string,
    process_id: number,
    sort: number,
    is_location: boolean,
    attach_files: number[],
    attach_files_info: Attachment[]
}

export interface IRequirementListData {
    id: number,
    title: string,
    type_id: number,
    child_type_id?: number,
    reason?: string,
    introduction?: string,
    expect_day?: number,
    budget_amount: number,
    status: number,
    quote?: number,
    assess_day?: number,
    attach_files: number[],
    process_id: null,
    publish_at: string,
    assess_at?: string,
    status_text: string,
    type_text?: string,
    child_type_text?: string,
    process?: {
        id: number,
        title: string
    },
    can_confirm: boolean
    can_upload_transfer: boolean,
    transfer_files_info: Attachment[],
    process_step: IRequirementListProcess[]
}

export interface IRequirementList {
    list: IRequirementListData[],
    total: number
}

export interface IRequirementListSearchForm extends Pager {
    keywords?: string
}

/**
 * 获取需求列表
 * @returns 
 */
export const getRequirementList = (data: IRequirementListSearchForm): Promise<CommonResponse<IRequirementList>> => {
    return axiosInstance.post('/company/demand/list', data).then(res => res.data);
};

/**
 * 需求确认
 * @param data 
 * @returns 
 */
export const toRequirementConfirm = (data: { id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/company/demand/confirm', data).then(res => res.data);
};

export interface IRequirementForm {
    title: string,
    type_id: number,
    child_type_ids?: number[],
    introduction?: string,
    expect_day?: number,
    budget_amount?: number,
    attach_files?: number[]
}

/**
 * 发布需求
 * @param data 
 * @returns 
 */
export const toPublishRequirement = (data: IRequirementForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/demand/publish', data).then(res => res.data);
};

export interface IQuickRequirementForm {
    type_id: number,
    child_type_ids?: number[],
}

/**
 * 快速发布需求
 * @param data 
 * @returns 
 */
 export const toQuickPublishRequirement = (data: IQuickRequirementForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/demand/quick_publish', data).then(res => res.data);
};


export interface IUploadRequirementLogForm {
    id: number,
    transfer_files: number[]
}

/**
 * 上传转账记录
 * @param data 
 * @returns 
 */
export const toUploadRequirementLog = (data: IUploadRequirementLogForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/demand/transfer_file', data).then(res => res.data);
};