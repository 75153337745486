import { useState, useEffect } from 'react';

// antd
import { Form, Input, message, Modal, Select } from 'antd';


// interface
import { Attachment } from '../../../apis/attachment';
import { FileUpload, uploadFile } from '../../../apis/common';
import { getReworkList, IReworkForm, IReworkListData, toRework } from '../../../apis/order';

const { TextArea } = Input;
const { Option } = Select;
function ReworkFormModal({
    visible,
    onFinish,
    onCancel,
    data
}: {
    visible: boolean,
    data: { id: number }
    onFinish: () => void,
    onCancel: () => void,
}) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [form] = Form.useForm<IReworkForm>();
    const [steps, setSteps] = useState<IReworkListData[]>([]);
    useEffect(() => {
        // 初始化
        if (visible) {
            form.resetFields();

            getReworkList({ order_id: data.id }).then(res => {
                setSteps(res.data.list);
            }).catch(e => {
                setSteps([]);
            });
        }
    }, [visible]);


    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);

        try {
            let res = await toRework({
                ..._form,
                order_id: data.id,
            });
            message.success(res.msg);
            onFinish();
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title={`操作返工`}
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                submit();
            }}
        >
            <Form
                className='inner-page'
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="选择返工步骤"
                    name="order_process_step_ids"
                    rules={[{ required: true, message: '请选择返工步骤' }]}
                >
                    <Select showArrow mode='multiple'>
                        {steps.map(option => (
                            <Option key={option.id} value={option.id}>{option.process_step_title}（{option.status_text}）</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="返工原因"
                    name="reason"
                >
                    <TextArea rows={4} placeholder="请填写返工原因" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ReworkFormModal;