import React, { useEffect } from 'react';

import {
    LoadingOutlined
} from '@ant-design/icons';

import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.scss';

import { SBD } from 'siboda_minipage';
import logo from '@assets/logo.svg';
import { Modal } from 'antd';
import { getUserInitData, sbdLogin } from '../../apis/user';

function GuidePage() {
    const [params, setParams] = useSearchParams();

    const navigate = useNavigate();

    let navFunc = () => {
        let aid = params.get('aid');
        let quick = params.get('quick');
        
        if (aid) {
            navigate(`/order/appraise/${aid}`, { replace: true });
            return;
        } 

        if (quick) {
            navigate(`/requirement/quick`, { replace: true });
            return;
        } 

        navigate("/order", { replace: true });
    }

    useEffect(() => {
        // 如果是思博达平台，直接跳转登录页
        if (SBD.isSibodaView()) {
            // 等待思博达app初始化页面
            SBD.onReady(() => {
                // 登录方法
                SBD.login().then(async (res) => {
                    if (res['status'] === 1) {
                        // login
                        let loginRes = await sbdLogin({ js_code: res['code'] });
                        localStorage.setItem('token', loginRes.data.token);

                        // 获取用户初始化信息
                        let initRes = await getUserInitData();
                        if (initRes.data.company) {
                            localStorage.setItem('user', JSON.stringify(initRes.data.company));
                            navFunc();
                        } else if (initRes.data.is_applying) {
                            navigate('/register/pending');
                        } else {
                            navigate('/register');
                        }
                    } else {
                        Modal.error({
                            title: '失败',
                            content: `登录失败，错误码：${res['status']}`,
                        });
                    }
                }).catch((e) => {
                    console.error(e);
                })
            })
            return;
        }
        const token = localStorage.getItem('token');
        if (token) {
            navFunc();
        } else {
            navigate("/login", { replace: true });
        }
    }, []);

    return (
        <div className='first-load'>
            <img src={logo} alt="logo" />
            <h2>雄智劳动争议顾问平台</h2>
            <LoadingOutlined style={{ fontSize: 40, color: '#FF5252', marginTop: '10vh' }} />
        </div>
    );
}

export default GuidePage;