import React, { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';

// interface
import { getRequirementList, IRequirementListData, IRequirementListProcess, IRequirementListSearchForm, toRequirementConfirm } from '../../apis/requirement';

// antd
import { Space, Table, Dropdown, Menu, Button, Image, Input, Modal, Grid, Pagination, Card, Empty, Tooltip, Timeline, Row, Col } from 'antd';
import { CloseOutlined, ExclamationCircleFilled, EyeOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';

import AddRequirementFormModal from './actions/addRequirementFormModal';
import UploadLogFormModal from './actions/uploadLogFormModal';
import { CustomImage } from '../../components/customImage/customImage';
import fileImage from '@assets/images/file.svg';
import { Attachment } from '../../apis/attachment';
import BottomNavigatorBar from '../../components/bottomNavigator/bottomNavigator';

const { confirm } = Modal;
const { Column } = Table;
const { useBreakpoint } = Grid;

function RequirementListPage() {
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IRequirementListSearchForm>({
        page: 1,
        size: 20,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IRequirementListData)[]>([]);
    const [mListData, setMListData] = useState<(IRequirementListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IRequirementListSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getRequirementList(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: IRequirementListSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData();
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    const toFinish = (record: IRequirementListData) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: `确认此需求 ${record.title} 的评估吗？`,
            onOk() {
                return new Promise((resolve, reject) => {
                    toRequirementConfirm({ id: record.id })
                        .then(res => {
                            resolve(res);
                            loadData();
                        })
                        .catch(e => { reject(e); })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }

    const showIntroduction = (value: string, e?: any) => {
        e?.stopPropagation();
        Modal.info({
            title: '项目介绍',
            content: value,
        });
    }

    // 显示步骤弹窗
    const [stepModalForm, setStepModalForm] = useState<{
        isModalVisible: boolean,
        data?: { value: IRequirementListProcess[] }
    }>();
    const showStep = (value: IRequirementListProcess[], e?: any) => {
        e?.stopPropagation();
        setStepModalForm({
            isModalVisible: true,
            data: { value }
        })
    }

    // 添加编辑弹窗
    const [addModalForm, setAddModalForm] = useState<{
        isModalVisible: boolean,
    }>();
    const toAdd = () => {
        setAddModalForm({
            isModalVisible: true,
        })
    };

    // 上传转账记录弹窗
    const [uploadModalForm, setUploadModalForm] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toUpload = (id: number) => {
        setUploadModalForm({
            isModalVisible: true,
            data: { id }
        })
    };

    // 查看上传转账记录
    const [imagesVisible, setImagesVisible] = useState(false);
    const [transferImages, setTransferImages] = useState<Attachment[]>([]);
    const showTransferImages = (values: Attachment[], e?: any) => {
        e?.stopPropagation();

        setTransferImages(values);
        setTimeout(() => {
            setImagesVisible(true);
        }, 200);
    }

    return (
        <div className='address inner-page' style={{ marginBottom: screens.xs ? 100 : 0 }}>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                <Button
                    type="primary"
                    className={screens.xs ? "search__button" : "search__last-button"}
                    icon={<PlusOutlined />}
                    onClick={toAdd}
                >
                    新增需求
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'confirm',
                                                disabled: !item.can_confirm,
                                                label: (
                                                    <div>确认</div>
                                                ),
                                                onClick: () => toFinish(item),
                                            },
                                            {
                                                key: 'upload',
                                                disabled: !item.can_upload_transfer,
                                                label: (
                                                    <div>上传转账记录</div>
                                                ),
                                                onClick: () => toUpload(item.id),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>项目名称：</div>
                                            <div>{item.title}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>期望完成周期：</div>
                                            <div>{item.expect_day}天</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>预算费用：</div>
                                            <div>{item.budget_amount ? item.budget_amount : '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>项目介绍：</div>
                                            <div>{
                                                item.introduction
                                                    ? <Button size='small' type='link' onClick={(e) => showIntroduction(item.introduction!, e)}>详情</Button>
                                                    : '无'
                                            }</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>平台报价：</div>
                                            <div>{item.quote ? item.quote : '等待回复'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>平台评估周期：</div>
                                            <div>{item.assess_day ? item.assess_day + '天' : '等待回复'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>项目流程：</div>
                                            <div>{
                                                item.process
                                                    ? <>
                                                        {item.process.title}
                                                        <Tooltip title="查看大概步骤">
                                                            <Button
                                                                type="text"
                                                                icon={<EyeOutlined style={{ color: '#1890ff' }} />}
                                                                shape="circle"
                                                                onClick={(e) => showStep(item.process_step, e)}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                    : '等待回复'}</div>
                                        </div>
                                        <div className='m-card_item col'>
                                            <div>补充说明：</div>
                                            <div>{item.reason}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>发布日期：</div>
                                            <div>{item.publish_at}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>回复日期：</div>
                                            <div>{item.assess_at}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>已上传转账记录：</div>
                                            <div>
                                                {
                                                    item.transfer_files_info.length > 0
                                                        ? <Button size='small' type='link' onClick={(e) => showTransferImages(item.transfer_files_info, e)}>查看</Button>
                                                        : '无'
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="项目名称" dataIndex="title" key="title" />
                        <Column
                            title="期望完成周期"
                            dataIndex="expect_day"
                            key="expect_day"
                            render={(value: number | null, record: IRequirementListData) => (
                                value ? value + '天' : '无'
                            )}
                        />
                        <Column
                            title="预算费用"
                            dataIndex="budget_amount"
                            key="budget_amount"
                            render={(value: number | null, record: IRequirementListData) => (
                                value ? `${value}` : '无'
                            )}
                        />
                        <Column
                            title="项目介绍"
                            dataIndex="introduction"
                            key="introduction"
                            render={(value: string | null, record: IRequirementListData) => (
                                value ? <Button type='link' onClick={() => showIntroduction(value)}>详情</Button> : '无'
                            )}
                        />

                        <Column
                            title="平台报价"
                            dataIndex="quote"
                            key="quote"
                            render={(value: number | null, record: IRequirementListData) => (
                                value ? `${value}` : '等待回复'
                            )}
                        />
                        <Column
                            title="平台评估周期"
                            dataIndex="assess_day"
                            key="assess_day"
                            render={(value: number | null, record: IRequirementListData) => (
                                value ? `${value}天` : '等待回复'
                            )}
                        />
                        <Column
                            title="项目流程"
                            dataIndex="process"
                            key="process"
                            render={(value: any, record: IRequirementListData) => (
                                value
                                    ? <div>
                                        {record.process?.title}
                                        <Tooltip title="查看大概步骤">
                                            <Button
                                                type="text"
                                                icon={<EyeOutlined style={{ color: '#1890ff' }} />}
                                                shape="circle"
                                                onClick={() => showStep(record.process_step)}
                                            />
                                        </Tooltip>
                                    </div>
                                    : '等待回复'
                            )}
                        />
                        <Column title="补充说明" dataIndex="reason" key="reason" />
                        <Column title="发布日期" dataIndex="publish_at" key="publish_at" />
                        <Column title="回复日期" dataIndex="assess_at" key="assess_at" />
                        <Column title="是否已上传转账记录" dataIndex="transfer_files_info" key="transfer_files_info"
                            render={(value: Attachment[] | null, record: IRequirementListData) => (
                                value?.length ?? 0 > 0 ? <Button size='small' onClick={() => showTransferImages(value!)}>查看</Button> : '无'
                            )}
                        />
                        <Column
                            width={100}
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IRequirementListData) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'confirm',
                                                        disabled: !record.can_confirm,
                                                        label: (
                                                            <div>确认</div>
                                                        ),
                                                        onClick: () => toFinish(record),
                                                    },
                                                    {
                                                        key: 'upload',
                                                        disabled: !record.can_upload_transfer,
                                                        label: (
                                                            <div>上传转账记录</div>
                                                        ),
                                                        onClick: () => toUpload(record.id),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 添加需求弹窗 */}
            {
                addModalForm
                    ? <AddRequirementFormModal
                        visible={addModalForm.isModalVisible}
                        onFinish={() => {
                            setAddModalForm({
                                ...addModalForm,
                                isModalVisible: false
                            })
                            resetData();
                        }}
                        onCancel={() => {
                            setAddModalForm({
                                ...addModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }

            {/* 上传转账记录弹窗 */}
            {
                uploadModalForm
                    ? <UploadLogFormModal
                        data={uploadModalForm.data}
                        visible={uploadModalForm.isModalVisible}
                        onFinish={() => {
                            setUploadModalForm({
                                ...uploadModalForm,
                                isModalVisible: false
                            })
                            resetData();
                        }}
                        onCancel={() => {
                            setUploadModalForm({
                                ...uploadModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }

            <Modal
                title="项目流程"
                open={stepModalForm?.isModalVisible}
                onCancel={() => {
                    setStepModalForm({
                        ...stepModalForm,
                        isModalVisible: false,
                    })
                }}
                footer={
                    <Button key="back" onClick={() => {
                        setStepModalForm({
                            ...stepModalForm,
                            isModalVisible: false,
                        })
                    }}>关闭</Button>
                }
                width={1000}
            >
                <Timeline>
                    {
                        stepModalForm?.data?.value.map(item => (
                            <Timeline.Item>
                                <h3>{item.title}</h3>
                                <div style={{ color: 'grey' }}>{item.desc ?? '暂无介绍'}</div>
                                <Row>
                                    {
                                        item.attach_files_info.map(attachment => (
                                            <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                                                <Card
                                                    size="small"
                                                    style={{ margin: 10 }}
                                                    cover={
                                                        attachment.attach_url.search(/\.[png|jpg|jpeg|bmp|gif]/) !== -1
                                                            ? <CustomImage
                                                                src={attachment.attach_url_link}
                                                            />
                                                            : <img
                                                                alt="文件"
                                                                src={fileImage}
                                                            />

                                                    }
                                                    actions={[
                                                        <DownloadOutlined key="download" onClick={() => window.open(attachment.attach_url_link, '_blank')} />,
                                                    ]}
                                                >
                                                    <div>{attachment.attach_name}</div>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            </Modal>

            <Image.PreviewGroup preview={{
                visible: imagesVisible,
                onVisibleChange: value => {
                    setImagesVisible(value);
                },
            }}>
                {transferImages.map(img => (
                    <Image
                        key={img.attach_name}
                        width={200}
                        style={{ display: 'none' }}
                        src={img.attach_url_link}
                    />
                ))}
            </Image.PreviewGroup>

            {screens.xs ? <BottomNavigatorBar current={1} /> : null}
        </div>
    );
}

export default RequirementListPage;