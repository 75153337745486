import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";


// 10 注册 20 登录 30 修改密码 40 绑定 50 转移管理员 60 更换手机
type codeTypeItems = 10 | 20 | 30 | 40 | 50 | 60
export const codeType: {
    [key: string]: codeTypeItems,
} = {
    register: 10, // 注册
    login: 20, // 登录
    password: 30, // 修改密码
    bind: 40, // 绑定
    transfer: 50, // 转移管理员
    changeMobile: 60 // 更换手机
};

export interface ISendVCodeForm {
    mobile: string,
    type: codeTypeItems
}

/**
 * 发送验证码
 * @param data 
 * @returns 
 */
export const sendVCode = (data: ISendVCodeForm): Promise<CommonResponse> => {
    return axiosInstance.post('/api/shares/sms_send', data).then(res => res.data);
};

export const loginType = {
    code: 1, // 验证码
    password: 2, // 密码
};

export interface ILoginForm {
    login_type: number,
    mobile: string,
    password?: string
    code?: string,
}

export interface ILoginData {
    token: string,
    is_new: boolean
}

/**
 * @description: 用户登录
 * @params {ILoginForm} params
 * @return {Promise}
 */
export const login = (data: ILoginForm): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.post('/company/auth/login', data).then(res => res.data);
};

/**
 * @description: 思博达小程序用户登录
 * @return {Promise}
 */
export const sbdLogin = (data: { js_code: string }): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.post('/company/auth/siboda', data).then(res => res.data);
};

export interface IUserInfo {
    id?: number,
    real_name?: string,
    name?: string,
    mobile?: string,
    status?: number,
    avatar_id?: number,
    idcard?: string,
    dept_name?: string,
    is_register?: number,
    company_id?: number,
    company_text?: string,
    status_text?: string,
    avatar_link?: string,

    bank_no?: string,
    bank_name?: string,
    bank_deposit?: string,
    social_no?: string,
}

export interface IUserInitData {
    company?: IUserInfo,
    is_applying: boolean,
    mobile: string
}

/**
 * @description: 获取用户初始化信息
 * @returns {Promise}
 */
export const getUserInitData = (): Promise<CommonResponse<IUserInitData>> => {
    return axiosInstance.get('/company/user/init_data').then(res => res.data);
};

export interface IRegisterForm {
    idcard: string,
    avatar_id?: number,
    dept_name?: string
    social_no: string,
    company_name: string,
    real_name: string,
}

/**
 * 注册
 * @param data 
 * @returns 
 */
export const register = (data: IRegisterForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/register/index', data).then(res => res.data);
};

export interface IResetPasswordForm {
    code: string,
    password: string,
    password_confirmation: string
}

/**
 * 修改密码
 * @param data 
 * @returns 
 */
export const changePassword = (data: IResetPasswordForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/user/set_password', data).then(res => res.data);
};

export interface IUserInfoData {
    id: number,
    real_name: string,
    mobile: string,
    avatar_id: number,
    idcard: string,
    dept_name: string,
    sex: number,
    birthday: string,
    company_id: number,
    company_text: string,
    avatar_link: string,
    sex_text: string,
    company: IUserInfo
}

export interface IUserInfoRes {
    detail: IUserInfoData
}

/**
 * 企业信息
 * @returns 
 */
export const getUserInfo = (): Promise<CommonResponse<IUserInfoRes>> => {
    return axiosInstance.get('/company/user/profile').then(res => res.data);
};

export interface IUpdateUserInfoForm {
    avatar_id?: number,
    dept_name?: string,
    bank_no?: string,
    bank_name?: string,
    bank_deposit?: string,
    social_no: string,
    company_name: string,
    real_name: string,
}

export const updateUserInfo = (data: IUpdateUserInfoForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/user/edit_profile', data).then(res => res.data);
};