import { Link, useLocation } from 'react-router-dom';

// antd
import { Badge } from 'antd';

// assets
import logo from '@assets/logo.svg';
import indexIcon from "@assets/navigator/index.svg";
import addressIcon from "@assets/navigator/address.svg";
import functionIcon from "@assets/navigator/function.svg";
import mineIcon from "@assets/navigator/mine.svg";
import messageIcon from "@assets/navigator/message.svg";

// scss
import './navigator.scss';

/**
 * 侧边导航条组件
 */
function NavigatorBar() {

    // 获取当前路径并激活对应模块
    const location = useLocation();
    const path = `/${location.pathname.split('/')[1]}`;
    console.log(`当前路径: ${path}`);

    // 导航栏
    const navList = [
        {
            name: '业务管理',
            path: '/order',
            icon: functionIcon,
            badge: 0
        },
        {
            name: '需求管理',
            path: "/requirement",
            icon: addressIcon,
            badge: 0
        }
    ];

    // 组装
    const navItems: JSX.Element[] = navList.map(e => {
        return (
            <Link className={`navigator__list__item ${path === e.path ? 'active' : ''}`} to={e.path} key={e.path}>
                <Badge offset={[-10, 10]} count={e.badge} overflowCount={99}>
                    <img className='navigator__list__item__img' src={e.icon} alt={e.name} />
                </Badge>
                <div className='navigator__list__item__title'>{e.name}</div>
            </Link>
        )
    });

    return (
        <div className='navigator'>
            <div className='inner'>
                {/* logo */}
                <div className='inner__logo-box'>
                    <img className='navigator__logo' src={logo} alt="logo" />
                    <span className='navigator__title'>雄智劳动争议<br />顾问平台</span>
                </div>

                {/* 列表 */}
                <div className='navigator__list'>{navItems}</div>
            </div>
        </div>
    );
}
export default NavigatorBar;