import { useState, useEffect } from 'react';

// antd
import { Button, Form, Input, InputNumber, message, Modal, Select, TreeSelect, Upload, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";

// interface
import { Attachment } from '../../../apis/attachment';
import { FileUpload, getDictList, getMultiDictList, IDictDataOption, IMultiDictListData, uploadFile } from '../../../apis/common';
import { IRequirementForm, toPublishRequirement } from '../../../apis/requirement';

const { Option } = Select;

function AddRequirementFormModal({
    visible,
    onFinish,
    onCancel,
}: {
    visible: boolean,
    onFinish: () => void,
    onCancel: () => void,
}) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 需求类型、争议类型
    const [type, setType] = useState<IMultiDictListData[]>([]);
    const [subType, setSubType] = useState<IMultiDictListData[]>([]);

    const onTypeChange = (value: number) => {
        setSubType(type.find(item => item.id === value)?.children ?? []);
        form.resetFields(['child_type_id']);
    }

    // 表单数据
    const [form] = Form.useForm<IRequirementForm>();
    useEffect(() => {
        // 初始化
        if (visible) {
            form.resetFields();
            setAttachments([]);

            getMultiDictList('demand_type').then(res => {
                setType(res.data.list);
            })
        }
    }, [visible]);


    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let res = await toPublishRequirement({
                ..._form,
                // 附件
                attach_files: attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    })
            });
            Modal.info({
                title: '成功',
                content: '您的需求我们已经收到，我们将在1个工作日内进行回复！请耐心等候！谢谢！',
                onOk: () => {
                    onFinish();
                },
                onCancel: () => {
                    onFinish();
                }
            });
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList);
        setAttachments(info.fileList);
    }
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'default' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    return (
        <Modal
            visible={visible}
            title={`发布需求`}
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        console.log(values);
                        submit();
                    })
            }}
        >
            <Form
                className='inner-page'
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="需求标题"
                    name="title"
                    rules={[{ required: true, message: '请输入需求标题' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="需求类型"
                    name="type_id"
                    rules={[{ required: true, message: '请选择需求类型' }]}
                >
                    <Select showArrow onChange={onTypeChange}>
                        {type.map(option => (
                            <Option key={option.id} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="需求子类型"
                    name="child_type_ids"
                >
                    <Select showArrow mode='multiple'>
                        {subType.map(option => (
                            <Option key={option.id} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="需求介绍"
                    name='introduction'
                >
                    <TextArea rows={4} placeholder="请填写需求介绍" />
                </Form.Item>

                <Form.Item
                    label="需求周期"
                    name="expect_day"
                >
                    <InputNumber addonAfter="天" precision={1} min={0} />
                </Form.Item>

                <Form.Item
                    label="预算金额"
                    name="budget_amount"
                >
                    <InputNumber addonAfter="元" precision={2} min={0} />
                </Form.Item>

                <Form.Item
                    label="附件补充"
                >
                    <Upload
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传文件</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddRequirementFormModal;