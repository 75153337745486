import React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import Compressor from 'compressorjs';

// apis
import { getUserInfo, IUserInfo, updateUserInfo, IUpdateUserInfoForm } from '../../apis/user';
import { uploadFile } from '../../apis/common';

// antd
import { Button, Form, message, Space, Input, Row, Col, DatePicker, Spin, Divider } from 'antd';

import avatarImg from '@assets/images/register/avatar.png';

function UserInfoFormPage() {

    const [form] = Form.useForm<IUpdateUserInfoForm>();

    // 获取用户信息
    useEffect(() => {
        getUserInfo().then(res => {
            let data = res.data.detail;
            localStorage.setItem('user', JSON.stringify(data));
            setInputAvatarImg(data.avatar_link);
            form.setFieldsValue({
                ...data,
                company_name: data.company_text,
                social_no: data.company.social_no,
                bank_no: data.company.bank_no,
                bank_name: data.company.bank_name,
                bank_deposit: data.company.bank_deposit,
            })

        });
    }, [form]);

    // 提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const submit = async () => {
        await form.validateFields();

        let rawForm = JSON.parse(JSON.stringify(form.getFieldsValue(true)));

        try {
            setSubmitLoading(true);
            let res = await updateUserInfo({
                ...rawForm,
            });
            message.success(res.msg);
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    }

    // 上传头像
    const [inputAvatarLoading, setInputAvatarLoading] = useState(false);
    const inputAvatarRef = React.createRef<HTMLInputElement>();
    const [inputAvatarImg, setInputAvatarImg] = useState<string>();
    const uploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputAvatarLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'company_logo' }).then(res => {
                    setInputAvatarImg(res.data.full_link);
                    form.setFieldValue('avatar_id', res.data.file_id);
                    setInputAvatarLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputAvatarLoading(false);
            },
        });
    };

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>个人信息</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                    is_idcard_long_time: false
                }}
            >
                <Row>
                    <Col xs={24} sm={24} md={11}>
                        <Form.Item noStyle>
                            <Row style={{ margin: "20px 0" }}>
                                <Col xs={4} sm={5} md={6} lg={7} xl={8}></Col>
                                <Col xs={16} sm={14} md={12} lg={10} xl={8}>
                                    <Spin spinning={inputAvatarLoading}>
                                        <img
                                            src={inputAvatarImg ?? avatarImg}
                                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                            alt="头像"
                                            onClick={() => inputAvatarRef.current?.click()}
                                        />
                                    </Spin>

                                    <input
                                        ref={inputAvatarRef}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept='image/jpeg,image/jpg,image/png'
                                        onChange={uploadAvatar}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item
                            label="企业名称"
                            name="company_name"
                            rules={[{ required: true, message: '请输入企业名称' }]}
                        >
                            <Input maxLength={10} />
                        </Form.Item>

                        <Form.Item
                            label="企业唯一信用代码"
                            name="social_no"
                            rules={[{ required: true, message: '请输入企业唯一信用代码' }]}
                        >
                            <Input maxLength={18} />
                        </Form.Item>

                        <Form.Item
                            label="部门名称"
                            name="dept_name"
                        >
                            <Input maxLength={20} />
                        </Form.Item>

                        <Form.Item
                            label="联系人"
                            name="real_name"
                            rules={[{ required: true, message: '请输入联系人' }]}
                        >
                            <Input maxLength={10} />
                        </Form.Item>
                    </Col>
                    <Col sm={0} md={2}> </Col>
                    <Col xs={24} sm={24} md={11}>
                        <Divider
                            orientation="left"
                            plain
                        >银行卡信息</Divider>

                        <Form.Item
                            label="银行名称"
                            name="bank_name"
                        >
                            <Input placeholder="银行名称" />
                        </Form.Item>

                        <Form.Item
                            label="银行卡号"
                            name="bank_no"
                        >
                            <Input
                                placeholder="银行卡号"
                                onKeyUp={(e) => { e.currentTarget.value = e.currentTarget.value.replace(/\D+/g, '') }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="开户支行"
                            name="bank_deposit"
                        >
                            <Input placeholder="开户支行" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Space>
                        <Button type='primary' onClick={submit} loading={submitLoading}>提交表单</Button>
                    </Space>
                </Form.Item>
            </Form>

            {/* <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt="logo" style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
        </div >
    );
}


export default UserInfoFormPage;