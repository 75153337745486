import React, { useEffect, useRef, useState } from 'react';
import './login.scss';

// antd
import { Button, Form, Input, message, Modal } from 'antd';
import { LockOutlined, MessageOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useGlobalCountDownSec } from '../../utils/countdown';
import { codeType, getUserInitData, ILoginForm, login, loginType, sbdLogin, sendVCode } from '../../apis/user';
import ReactSimpleVerify from '../../components/validator/validator';

/**
 * 登录表单
 */
function LoginForm() {
    // 路由
    const navigate = useNavigate();

    // 表单实例
    const [form] = Form.useForm();

    // 验证码
    const verifyRef = useRef<ReactSimpleVerify>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        verifyRef.current?.reset();
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    /**
     * 1: 手机验证码登录(默认)
     * 2: 账号密码登录
     */
    const [loginWay, setLoginWay] = useState(loginType.code);

    // 验证码冷却
    const [countdown, startCountdown] = useGlobalCountDownSec(60, 'login');

    // 获取验证码点击事件
    const onGetCodeButtonClick = async () => {
        try {
            const { mobile } = await form.validateFields(['mobile'])
            console.log(`mobile: ${mobile}`);
            // 开始倒计时
            startCountdown();
            // 发送请求
            let res = await sendVCode({ mobile, type: codeType.login });
            message.success(res.msg);
        } catch (e) {
            console.log(e);
        }
    };

    // 获取验证码按钮
    const getCodeButton = () => (
        <Button
            type="link"
            loading={countdown > 0}
            onClick={onGetCodeButtonClick}
        >{countdown > 0 ? `${countdown}s` : '获取验证码'}</Button>
    );

    // 表单提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async (values: ILoginForm) => {
        values.login_type = loginWay;
        console.log('Received values of form: ', values);
        try {
            setSubmitLoading(true);
            let res = await login(values);
            localStorage.setItem('token', res.data.token);

            // 获取用户初始化信息
            let initRes = await getUserInitData();
            if (initRes.data.company) {
                localStorage.setItem('user', JSON.stringify(initRes.data.company));
                navigate('/');
            } else if (initRes.data.is_applying) {
                navigate('/register/pending');
            } else {
                navigate('/register');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        // 流式布局
        <div className='login-form'>
            {/* 标题（登录方式） */}
            <div className='login-form__title'>{loginWay === loginType.code ? '手机验证码登录' : '账号密码登录'}</div>

            {/* 表单 */}
            <Form
                form={form}
                name="login"
                className="login-form__form"
                initialValues={{ agreement: true }}
            >
                {/* 手机号 */}
                <Form.Item
                    name="mobile"
                    rules={[{ required: true, message: '请输入手机号' }]}
                >
                    <Input prefix={<UserOutlined className="login-form__form__item-icon" size={50} />} placeholder="请输入手机号" />
                </Form.Item>

                {/* 不同登录方式 */}
                {
                    loginWay === loginType.code
                        // 验证码
                        ? <>
                            <Form.Item
                                name="code"
                                rules={[{ required: true, message: '请输入验证码' }]}
                            >
                                <Input
                                    prefix={<MessageOutlined className="login-form__form__item-icon" />}
                                    suffix={getCodeButton()}
                                    placeholder="请输入验证码"
                                />
                            </Form.Item>
                            <div className='login-form__form__tip'>未注册的手机号验证后自动创建账号</div>
                        </>
                        // 密码
                        : <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请输入密码' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="login-form__form__item-icon" />}
                                type="password"
                                placeholder="请输入密码"
                            />
                        </Form.Item>
                }

                {/* 间距 */}
                <div className='login-form__form__padding'></div>

                {/* 登录按钮 */}
                <Form.Item>
                    <Button
                        loading={submitLoading}
                        type="primary"
                        block
                        className="login-form__form__button"
                        onClick={() => {
                            form.validateFields().then((values) => {
                                showModal();
                            });
                        }}
                    >
                        立即登录
                    </Button>
                </Form.Item>

                {/* 切换登录方式 */}
                <Button
                    className='login-form__form__way-button'
                    type="link"
                    block
                    onClick={() => setLoginWay(loginWay === loginType.code ? loginType.password : loginType.code)}
                >
                    {loginWay === loginType.password ? '使用手机验证码登录' : '使用账号密码登录'}
                </Button>
            </Form>

            <Modal title="验证码" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ReactSimpleVerify ref={verifyRef} success={() => {
                        form.validateFields().then((values) => {
                            setTimeout(() => {
                                handleOk();
                            }, 500);
                            onSubmit(values);
                        });
                    }}></ReactSimpleVerify>
                </div>
            </Modal>
        </div >
    );
}
export default LoginForm;