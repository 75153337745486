import React, { useEffect, useState } from 'react';

// apis
import { getOrderDetail, IOrderDetailStep } from '../../apis/order';

// antd
import { Button, Card, Descriptions, Divider, List } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import './detail.scss';
import LocationDisplay from '../../components/locationDisplay/locationDisplay';

function OrderDetailPage() {
    const { id } = useParams();

    // 获取用户信息
    const [stepList, setStepList] = useState<IOrderDetailStep[]>([]);
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getOrderDetail({ order_id: Number(id) }).then(res => {
            setStepList(res.data.list);
        });
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>项目信息</div>
            </div>

            <div>
                {
                    stepList.map(step => (
                        <>
                            <Descriptions column={1} title={`${step.process_step_title}步骤：`}>
                                <Descriptions.Item label="执行人">{step.order_process_step_detail.map(item => item.adviser.name).join('，')}</Descriptions.Item>
                                <Descriptions.Item label="状态">{step.status_text ?? '无'}</Descriptions.Item>
                                {
                                    step.order_process_step_detail.map(item => (
                                        <Descriptions.Item>
                                            <Descriptions title={`${item.adviser.name} 执行情况`} column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3 }}>
                                                <Descriptions.Item label="状态">{item.status_text}</Descriptions.Item>
                                                {item.time_consuming_text ? <Descriptions.Item label="耗时">{item.time_consuming_text}</Descriptions.Item> : null}
                                                {item.desc ? <Descriptions.Item label="说明">{item.desc}</Descriptions.Item> : null}
                                                {(item.attach_files_info?.length ?? 0) > 0
                                                    ? <Descriptions.Item span={3}>
                                                        <Descriptions layout="vertical">
                                                            <Descriptions.Item label="附件">
                                                                {
                                                                    <List
                                                                        className='record__attachment'
                                                                        itemLayout="horizontal"
                                                                        dataSource={item.attach_files_info}
                                                                        renderItem={file => (
                                                                            <li>
                                                                                <Card bodyStyle={{ padding: "0 10px" }}>
                                                                                    <div className='record__attachment__card'>
                                                                                        <div>{file.attach_name}</div>
                                                                                        <Button type="text" icon={<DownloadOutlined />} href={file.attach_url_link} target="_blank" />
                                                                                    </div>
                                                                                </Card>
                                                                            </li>
                                                                        )}
                                                                    />
                                                                }
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Descriptions.Item>
                                                    : null}
                                                {item.address ? <Descriptions.Item label="位置">{item.address}</Descriptions.Item> : null}
                                                {item.lat && item.lng
                                                    ? <>
                                                        <Descriptions.Item span={3}><></></Descriptions.Item>
                                                        <Descriptions.Item span={1}>
                                                            <LocationDisplay location={[item.lng, item.lat]}></LocationDisplay>
                                                        </Descriptions.Item>
                                                    </>
                                                    : null}
                                            </Descriptions>
                                        </Descriptions.Item>
                                    ))
                                }
                            </Descriptions>

                            <Divider></Divider>
                        </>
                    )
                    )
                }
            </div>
        </div>
    );
}


export default OrderDetailPage;