import { axiosInstance, CommonResponse } from ".";
import { ICompany } from "./company";

export interface Pager {
    page: number,
    size: number
}

/**
 * 字典
 */
export type IDictName = keyof IDictList

export interface IDictDataOption {
    name: string,
    value: number
}

export interface IDictData {
    type_name: string,
    options: IDictDataOption[]
}

export interface IDictList {
    duty?: IDictData, // 职务
    scale?: IDictData, // 企业规模
    business?: IDictData, // 行业
    source_type?: IDictData, // 来源
    sys_nation?: IDictData,
    global_sex?: IDictData,
    sys_marry_type?: IDictData,
    sys_face?: IDictData,
    sys_residence_type?: IDictData,
    sys_relationship?: IDictData
}

/**
 * @description: 获取服务器字典列表
 * @returns {Promise}
 */
export const getDictList = (types: IDictName[]): Promise<CommonResponse<IDictList>> => {
    return axiosInstance.post('/api/shares/options', { types }).then(res => res.data);
};


export interface IMultiDictListData {
    id: number,
    pid: number,
    name: string,
    sort: number,
    initial: string,
    updated_at: string,
    children: IMultiDictListData[]
}

export interface IMultiDictList {
    list: IMultiDictListData[]
}

export type IMultiDictName = 'demand_type';

/**
 * @description: 获取服务器多层级字典列表
 * @returns {Promise}
 */
export const getMultiDictList = (type: IMultiDictName): Promise<CommonResponse<IMultiDictList>> => {
    return axiosInstance.post('/api/shares/dict_multi', { type }).then(res => res.data);
};



export interface IWxConfig {
    appId: string,
    nonceStr: string,
    timestamp: number,
    signature: string
}

/**
 * @description: 获取微信配置
 * @returns {Promise}
 */
export const getWxConfig = (): Promise<CommonResponse<IWxConfig>> => {
    return axiosInstance.get('/api/wechat/jssdk_config').then(res => res.data);
}

export interface RegionListData {
    id: number,
    name: string,
    initial: string
}

interface RegionList {
    items: RegionListData[]
}

/**
 * @description: 获取省市区字典列表
 * @returns {Promise}
 */
export const getRegionByPid = (params: { pid?: number }): Promise<CommonResponse<RegionList>> => {
    return axiosInstance.get('/api/shares/region', { params }).then(res => res.data);
}

/**
 * 上传文件类型
 */
export type uploadTypeValues =
    'default' | // 默认
    'blacklist' | // 黑名单
    'license' | // 营业执照
    'school' | // 学校
    'idcard' | // 身份证
    'staff_import' | // 导入员工
    'bank_card' | // 银行卡
    'work_order' | // 工作工单
    'work_plan' | // 工作计划
    'customer' | // 客户
    'work_record' | // 工作汇报
    'tracking' | // 轨迹追踪
    'company_logo' | // 企业logo
    'environment' | // 企业环境
    'salary' | // 薪资
    'salary_accounting_deduct' | // 薪资扣除
    'approval' | // 审批
    'tax_sheet_undeclare' | // 未申报薪资列表
    'staff_holiday' | // 年假
    'adviser_logo' |// 头像
    'order' | // 业务订单
    'adviser_qualification'; // 资质

export interface FileUpload {
    full_link: string,
    link: string,
    file_id: number,
    origin_name?: string,
    local_path?: string
}

/**
 * @description: 上传文件
 * @returns {Promise}
 */
export const uploadFile = ({ file, type }: { file: Blob, type: uploadTypeValues }): Promise<CommonResponse<FileUpload>> => {
    let form = new FormData();
    form.append('file', file);
    form.append('type', type);
    return axiosInstance.post('/api/shares/file_upload', form).then(res => res.data);
}

export interface IdcardOcr {
    name?: string,
    sex?: number,
    sex_code?: number,
    nation?: string,
    nation_code?: number,
    birth?: string,
    address?: string,
    id_num?: string,
    authority?: string,
    valid_date_start?: string,
    valid_date_end?: string
}

/**
 * 身份证识别
 * @param params 
 * @returns 
 */
export const getIdcardOcrData = (params: { fid: number }): Promise<CommonResponse<IdcardOcr>> => {
    return axiosInstance.get('/manage/ocr/idcard', { params }).then(res => res.data);
}

export interface BankCardOcr {
    bank_no: string,
    bank_name: string
}

/**
 * 银行卡识别
 * @param params 
 * @returns 
 */
export const getBankCardOcrData = (params: { fid: number }): Promise<CommonResponse<BankCardOcr>> => {
    return axiosInstance.get('/manage/ocr/scan',
        {
            params: {
                ...params,
                type: "bankCard"
            }
        }).then(res => res.data);
}

export interface IAllCompany {
    list: ICompany[]
}

/**
 * 获取所有企业
 * @returns 
 */
export const getAllCompany = (): Promise<CommonResponse<IAllCompany>> => {
    return axiosInstance.get('/salesman/company/all').then(res => res.data);
}