import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";
import { ICompany } from "./company";
import { IUserInfo } from "./user";

export interface IProcessStep {
    id: number,
    order_id: number,
    process_id: number,
    process_step_id: number,
    process_step_title: string,
    process_step_sort: number,
    score?: null,
    appraise?: null,
    status_text?: string
}

export interface IOrder {
    id: number,
    title: string,
    company_id: number,
    status: number,
    process_title?: string,
    status_text: string,
    current_process_step: IProcessStep,
    current_process_step_adviser: IUserInfo[],
    created_at: string,
    updated_at: string,
    can_acceptance: boolean,
    can_rework: boolean,
    can_company_appraise: boolean
}

export interface IOrderList {
    list: IOrder[],
    total: number
}

export interface IOrderListSearchForm extends Pager {
    keywords?: string
}

/**
 * 获取业务列表
 * @returns 
 */
export const getOrderList = (data: IOrderListSearchForm): Promise<CommonResponse<IOrderList>> => {
    return axiosInstance.post('/company/order/list', data).then(res => res.data);
};

export interface IOrderDetail {
    id: number,
    title: string,
    money: number,
    process_id: number,
    process_title?: string,
    attach_files: number[],
    attach_files_info: Attachment[]
}

export interface IOrderDetailProcessStepDetail {
    id: number,
    order_process_step_id: number,
    adviser_id: number,
    status: number,
    execute_begin_at: string,
    execute_time: {
        begin_at: string,
        end_at: string
    }[],
    time_consuming: number,
    lat?: number,
    lng?: number,
    address?: string,
    desc: string,
    attach_files: number[],
    status_text: string,
    time_consuming_text: string,
    adviser: {
        id: number,
        name: string,
        avatar_link?: string,
        sex_text?: string
    },
    attach_files_info: Attachment[]
}

export interface IOrderDetailStep {
    id: number,
    order_id: number,
    process_step_title: string,
    process_step_desc?: string,
    process_step_is_location: boolean,
    status_text?: string,
    order_process_step_detail: IOrderDetailProcessStepDetail[],
    can_company_appraise?: boolean,
}

export interface IOrderDetailOrder {
    id: number,
    title: string,
    status_text?: string
}

export interface IOrderDetailRes {
    order: IOrderDetailOrder,
    list: IOrderDetailStep[]
}

/**
 * 获取订单步骤详情
 * @returns 
 */
export const getOrderDetail = (data: { order_id: number, order_process_step_id?: number }): Promise<CommonResponse<IOrderDetailRes>> => {
    return axiosInstance.post('/company/order/process_step_detail', data).then(res => res.data);
};


/**
 * 业务验收
 * @param data 
 * @returns 
 */
export const toAcceptance = (data: { order_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/company/order/acceptance', data).then(res => res.data);
};

export interface AppraiseFormOrderProcessStep {
    id: number,
    score?: number,
    appraise?: string
}

export interface AppraiseForm {
    order_id: number,
    appraise_type: number,
    score?: number,
    appraise?: string,
    order_process_step?: AppraiseFormOrderProcessStep[]
}

/**
 * 业务评价
 * @param data 
 * @returns 
 */
export const toAppraise = (data: AppraiseForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/order/appraise', data).then(res => res.data);
};

export interface IReworkListData {
    id: number,
    process_step_title: string,
    status: number,
    status_text: string
}

export interface IReworkListRes {
    list: IReworkListData[]
}

/**
 * 返工可选步骤
 * @param data 
 * @returns 
 */
export const getReworkList = (data: { order_id: number }): Promise<CommonResponse<IReworkListRes>> => {
    return axiosInstance.post('/company/order/rework_order_process_step', data).then(res => res.data);
};

export interface IReworkForm {
    order_id: number,
    order_process_step_ids: number[],
    reason: string
}

/**
 * 返工
 * @param data 
 * @returns 
 */
export const toRework = (data: IReworkForm): Promise<CommonResponse> => {
    return axiosInstance.post('/company/order/rework_apply', data).then(res => res.data);
};
