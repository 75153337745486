import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// interface
import { IQuickRequirementForm, toQuickPublishRequirement } from '../../apis/requirement';

// antd
import { Button, Form, Select, message, Modal } from 'antd';
import { PhoneTwoTone } from '@ant-design/icons';

import { getMultiDictList, IMultiDictListData } from '../../apis/common';

import { SBD } from 'siboda_minipage';


const { Option } = Select;

function QuickRequirementFormPage() {
    const navigate = useNavigate();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 需求类型、争议类型
    const [type, setType] = useState<IMultiDictListData[]>([]);
    const [subType, setSubType] = useState<IMultiDictListData[]>([]);

    const onTypeChange = (value: number) => {
        setSubType(type.find(item => item.id === value)?.children ?? []);
        form.resetFields(['child_type_id']);
    }

    // 表单数据
    const [form] = Form.useForm<IQuickRequirementForm>();
    useEffect(() => {
        getMultiDictList('demand_type').then(res => {
            setType(res.data.list);
            // 固定选择
            form.setFieldValue('type_id', 1);
            setSubType(res.data.list.find(item => item.id === 1)?.children ?? []);
        });
    }, []);


    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let res = await toQuickPublishRequirement({
                ..._form,
            });
            Modal.info({
                title: '成功',
                content: '您的需求我们已经收到，我们将在1个工作日内进行回复！请耐心等候！谢谢！',
                onOk: () => {
                    navigate('/requirement');
                },
                onCancel: () => {
                    navigate('/requirement');
                }
            });
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 拨打电话
    const phoneNumber = '18925974603';
    const callPhone = async () => {
        if (SBD.isSibodaView() && SBD.isReady()) {
            let res = SBD.callPhone({ phoneNumber });
            console.log(res);
        } else {
            navigator.clipboard.writeText(phoneNumber);
            message.success('已复制到剪切板');
        }
    }


    return (
        <div className='menu address inner-page'>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <div className='menu__title'>争议调解</div>

                <div>服务时间：08:45 - 17:30（周一到周五）周六 非固定时间</div>
                <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <Button shape="circle" icon={<PhoneTwoTone />} size="large" onClick={callPhone} />
                    <div style={{ marginLeft: 10 }}>服务电话：{phoneNumber}</div>
                </div>
                <Form.Item
                    label="需求类型"
                    name="type_id"
                    rules={[{ required: true, message: '请选择需求类型' }]}
                >
                    <Select showArrow onChange={onTypeChange} disabled>
                        {type.map(option => (
                            <Option key={option.id} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="需求子类型"
                    name="child_type_ids"
                >
                    <Select showArrow mode='multiple'>
                        {subType.map(option => (
                            <Option key={option.id} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button size='large' loading={confirmLoading} type='primary' block onClick={submit}>提交</Button>
            </Form>
        </div>
    );
}

export default QuickRequirementFormPage;