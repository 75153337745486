import { useState, useEffect } from 'react';

// antd
import { Button, Form, message, Modal, Upload, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";

// interface
import { Attachment } from '../../../apis/attachment';
import { FileUpload, uploadFile } from '../../../apis/common';
import { toUploadRequirementLog } from '../../../apis/requirement';

function UploadLogFormModal({
    visible,
    onFinish,
    onCancel,
    data
}: {
    visible: boolean,
    data: { id: number }
    onFinish: () => void,
    onCancel: () => void,
}) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        // 初始化
        if (visible) {
            setAttachments([]);
        }
    }, [visible]);


    // 提交
    const submit = async () => {
        if (attachments.length === 0) {
            message.warning('请至少上传一个转账记录');
            return;
        }
        setConfirmLoading(true);
        try {
            let res = await toUploadRequirementLog({
                id: data.id,
                // 附件
                transfer_files: attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    })
            });
            message.success(res.msg);
            onFinish();
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList);
        setAttachments(info.fileList);
    }
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'default' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    return (
        <Modal
            visible={visible}
            title={`上传转账记录`}
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                submit();
            }}
        >
            <Form
                className='inner-page'
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="上传转账记录"
                >
                    <Upload
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传文件</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default UploadLogFormModal;